<template>
  <div class="card border-0">
    <div class="card-header f-w-600">安全检查整改</div>
    <div class="card-body height-auto m-0 p-0">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >整改结果：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <input
                  class="form-control"
                  v-model="Safecheck.change"
                />
              </div>
            </div>
            <!-- <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >证照类型：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="Safecheck.dictId"
                />
              </div>
            </div> -->
            <div class="form-group row">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >照片：</label
              >
              <div class="page-content col-lg-9 col-md-9">
                <div>
                  <upload
                    :maxSize="10"
                    :maxFiles="10"
                    :value="Safecheck.fileIds"
                    @finishUpload="finishAll"
                  >
                  </upload>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer text-right f-w-600">
        <button
          class="btn btn-sm btn-success m-r-10"
          type="submit"
          @click="checkForm"
        >
          <i class="fa fa-save"></i> 保存
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="btn btn-sm btn-grey m-r-10"
        >
          ❌关闭
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { securitycheckApi } from "@/api/securitycheck/securitycheckApi";
export default {
  props: ["Safecheckid"],
  data() {
    return {
      Safecheck: {
        id: "",
        change: "",
        fileIds: "",
      },
    };
  },
  created() {},
  mounted() {
    //编辑时获取证照信息
    // this.GetSafecheck();
  },
  methods: {
    checkForm: function(e) {
      e.preventDefault();
      const params = {
        id: this.Safecheckid,
        change: this.Safecheck.change,
        fileIds: this.Safecheck.fileIds.toString(),
      };

      //e.preventDefault();
      let that = this;
      if (that.Safecheckid) {
        securitycheckApi
          .SecurityCheckChanged(params)
          .then((res) => that.SecurityCheckChangedSuccess(that, res))
          .finally(() => {});
      }
    },
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },

    SecurityCheckChangedSuccess(that, res) {
      if (res.success) {
        this.ToastSuccess(res);
        this.$emit("close");
      } else {
        this.ToastWarning(res);
      }
    },
    finishAll(e) {
      this.Safecheck.fileIds = e;
      console.log(e);
    },
  },
};
</script>
