<template>
  <div class="page-content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
      <div class="panel-body">
        <h1 class="page-header">安全检查列表 <small></small></h1>
        <vxe-toolbar ref="xToolbar" :loading="loading" custom zoom print>
          <template #buttons>
            <!-- <input
              width="20%"
              v-model="BusinessName"
              type="text"
              @keyup="SecurityCheck"
              placeholder="请输入公司名称查询"
            /> -->
            <vxe-input
              style="width: 200px"
              v-model="BusinessName"
              size="mini"
              type="text"
              @keyup.enter.native="SecurityCheck"
              placeholder="请输入公司名称查询"
            >
            </vxe-input>
            <vxe-button
              icon="fa fa-search"
              size="mini"
              status="primary"
              @click="SecurityCheck"
              >查询</vxe-button
            >
            <vxe-button
              status="primary"
              size="mini"
              icon="fa fa-plus"
              content="新增"
              @click="insertEvent"
            ></vxe-button>
            <div class="btn-group btn-group-justified m-l-20">
              <a
                v-bind:class="
                  All ? 'btn btn-default active' : 'btn btn-default'
                "
                v-on:click="Stateselect(3)"
                >全部</a
              ><a
                v-bind:class="
                  Changed ? 'btn btn-default active' : 'btn btn-default'
                "
                v-on:click="Stateselect(0)"
                >待整改</a
              >
              <a
                v-bind:class="
                  Examined ? 'btn btn-default active' : 'btn btn-default'
                "
                v-on:click="Stateselect(1)"
                >待审核</a
              >
              <a
                v-bind:class="
                  Finish ? 'btn btn-default active' : 'btn btn-default'
                "
                v-on:click="Stateselect(2)"
                >已审核</a
              >
            </div>
          </template>
        </vxe-toolbar>
        <vxe-table
          round
          border
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :height="tabelHeight"
          :loading="loading"
          :print-config="{}"
          :data="tableData"
          ref="xTable"
          :column-config="{ resizable: true }"
        >
          <vxe-column
            title="序号"
            type="seq"
            width="100"
            fixed="left"
            align="center"
          ></vxe-column>
          <vxe-column width="400" title="所属企业" align="left">
            <template #default="{ row }">
              <a href="#" @click="editEvent(row)"> {{ row.businessName }} </a>
            </template>
          </vxe-column>
          <vxe-column
            field="questionCount"
            title="隐患数量"
            width="100"
            align="center"
          ></vxe-column>

          <vxe-column
            field="creationTime"
            title="时间"
            width="300"
            :formatter="formatTime"
          ></vxe-column>
          <!-- <vxe-column
        field=""
        title="检查类型"
        width="200"
        align="center"
      ></vxe-column>
      <vxe-column field="" title="状态" width="200"></vxe-column> -->
          <vxe-column title="操作" fixed="right" width="400" align="left">
            <template #default="{ row }">
              <vxe-button
                size="mini"
                class="m-r-5"
                status="primary"
                @click="editEvent(row)"
                >查看
              </vxe-button>
              <vxe-button
                size="mini"
                status="warning"
                icon="fa fa-trash-alt"
                @click="removeRowEvent(row)"
                >删除</vxe-button
              >
              <vxe-button
                v-bind:class="Examined ? 'hide' : 'btn btn-default'"
                size="mini"
                status="danger"
                icon="fas fa-exclamation-circle"
                @click="SafecheckChangeEvent(row)"
                >整改</vxe-button
              >
              <vxe-button
                v-bind:class="Finish ? 'hide' : 'btn btn-default'"
                size="mini"
                status="success"
                icon="fas fa-check-circle"
                @click="CheckSafecheckChangeEvent(row)"
                >审核</vxe-button
              >
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager
          border
          icon-prev-page="fa fa-angle-left"
          icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right"
          icon-next-page="fa fa-angle-right"
          icon-jump-more="fa fa-ellipsis-h"
          :loading="loading"
          :current-page="tablePage.currentPage"
          :page-size="tablePage.pageSize"
          :total="tablePage.totalResult"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          @page-change="handlePageChange"
        >
        </vxe-pager>
      </div>
    </div>
  </div>
</template>
<script>
import { securitycheckApi } from "@/api/securitycheck/securitycheckApi";
import SafecheckChange from "./SafecheckChange.vue"; //安全检查整改页面
import CheckSafecheckChange from "./CheckSafecheckChange.vue"; //安全检查整改页面

export default {
  data() {
    return {
      tabelHeight: document.body.clientHeight - 300,
      BusinessName: "",
      loading: false,
      Changed: false,
      Examined: false,
      Finish: false,
      All: true,
      tableData: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      formatTime(Value) {
        if (Value.length != 0) {
          return Value.cellValue.slice(0, 10);
        }
      },
    };
  },
  watch: {
    $route(to) {
      if (to.path == "/SecurityCheckList") {
        this.GetSecurityCheckList();
      }
    },
  },
  created() {},
  mounted() {
    // console.log(this.$route.query.id);
    this.GetSecurityCheckList();
    let that = this;
    window.onresize = () => {
      return (() => {
        that.tabelHeight = document.body.clientHeight - 300;
      })();
    };
  },

  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "删除成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    //进入安全检查编辑页面
    editEvent(row) {
      this.$router.push({
        path: "/SecurityCheck?id=" + row.id,
        meta: {
          title: row.title, // 页签标题
        },
      });
    },
    //获取安全检查列表数据

    GetSecurityCheckList(State) {
      let that = this;
      that.loading = true;
      securitycheckApi
        .GetSecurityCheckList({
          // 分页入参
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize, //起始页数
          MaxResultCount: that.tablePage.pageSize, //每页几条
          Sorting: "", //排序字段
          Filter: this.BusinessName.replace(/^\s+|\s+$/g, ""), //名称模糊搜索
          BusinessId: that.$route.query.id,
          State: State,
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    // 获取成功
    GetSuccess(that, res) {
      if (res.success) {
        that.tableData = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    //
    removeRowEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        icon: "warning",
        text: "是否确定删除?",
        type: "info",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              securitycheckApi
                .DeleteSecurityCheck({ id: row.id })
                .then((res) => that.DeleteSecurityCheckSuccess(that, res, row))
                .finally(() => {});
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    DeleteSecurityCheckSuccess(that, res, row) {
      if (res.success) {
        // Api请求成功之后前端页面删除该行
        const $table = this.$refs.xTable;
        $table.remove(row);
        this.ToastSuccess(res);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    //跳出安全检查整改页面
    SafecheckChangeEvent(row) {
      if (row.state == 1) {
        this.$swal({
          // title: "Are you sure?",
          icon: "warning",
          text: "该安全检查已完成整改！",
          type: "info",
          // showCancelButton: true, //确定按钮
          buttonsStyling: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
          cancelButtonClass: "btn btn-default", //按钮类型设置
        });
      } else {
        if (row.id) {
          this.$modal.show(
            SafecheckChange, //组件引用页面
            { Safecheckid: row.id }, //入参值
            { height: "auto", width: "50%", draggable: true }, //跳出框参数
            { "before-close": this.beforeClose }
          );
        } else {
          alert("请保存企业后添加");
        }
      }
    },
    beforeClose() {
      this.tablePage.currentPage = 1;
      if (this.Changed == true) {
        this.GetSecurityCheckList(0);
      } else if (this.Examined == true) {
        this.GetSecurityCheckList(1);
      } else if (this.Finish == true) {
        this.GetSecurityCheckList(2);
      } else if (this.All == true) {
        this.GetSecurityCheckList(3);
      }
    },
    //跳出安全检查审核页面
    CheckSafecheckChangeEvent(row) {
      if (row.questionCount > 0) {
        this.$swal({
          // title: "Are you sure?",
          icon: "warning",
          text: "请隐患数量清零后审核！",
          type: "info",
          // showCancelButton: true, //确定按钮
          buttonsStyling: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
          cancelButtonClass: "btn btn-default", //按钮类型设置
        });
      } else {
        if (row.state == 2) {
          this.$swal({
            // title: "Are you sure?",
            icon: "success",
            text: "该安全检查已审核！",
            type: "info",
            // showCancelButton: true, //确定按钮
            buttonsStyling: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
            cancelButtonClass: "btn btn-default", //按钮类型设置
          });
        } else if (row.state == 0) {
          this.$swal({
            // title: "Are you sure?",
            icon: "warning",
            text: "该安全检查未整改，请整改后审核！",
            type: "info",
            // showCancelButton: true, //确定按钮
            buttonsStyling: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
            cancelButtonClass: "btn btn-default", //按钮类型设置
          });
        } else {
          if (row.id) {
            this.$modal.show(
              CheckSafecheckChange, //组件引用页面
              { Safecheckid: row.id }, //入参值
              { height: "auto", width: "50%", draggable: true }, //跳出框参数
              { "before-close": this.beforeClose }
            );
          } else {
            alert("请保存企业后添加");
          }
        }
      }
    },
    //键盘他抬起事件，用于列表查询
    SecurityCheck() {
      this.tablePage.currentPage = 1;
      if (this.Changed == true) {
        this.GetSecurityCheckList(0);
      } else if (this.Examined == true) {
        this.GetSecurityCheckList(1);
      } else if (this.Finish == true) {
        this.GetSecurityCheckList(2);
      } else if (this.All == true) {
        this.GetSecurityCheckList();
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.GetSecurityCheckList();
      // this.findList();
    },
    // 进入安全检查新增页面
    async insertEvent() {
      this.$router.push({ path: "/SecurityCheck?id=" });
    },
    Stateselect(State) {
      this.Changed = false;
      this.Examined = false;
      this.Finish = false;
      this.All = false;
      this.tablePage.currentPage = 1;
      if (State == 0) {
        this.GetSecurityCheckList(0);
        this.Changed = true;
      } else if (State == 1) {
        this.GetSecurityCheckList(1);
        this.Examined = true;
      } else if (State == 2) {
        this.GetSecurityCheckList(2);
        this.Finish = true;
      } else if (State == 3) {
        this.GetSecurityCheckList();
        this.All = true;
      }
    },
  },
};
</script>
<style scoped>
.vxe-body--column,
.vxe-body--column {
  padding: 0;
}
</style>
